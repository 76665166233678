import dynamic from "next/dynamic";
import { Container, Typography, Box, useMediaQuery, Grid, Stack, Button } from "@mui/material";
import { useTheme } from "@mui/system";

import MainLayout from "../ui/layouts/main";
import { HotelListSlider } from "../ui/components/property/HotelListSlider";

import { FormSearch } from "../ui/components/FormSearch/FormSearch";

import { useTranslation } from "../fe-ui/hooks/use-translation";
import { OffersListWidgetLazy } from "../ui/widgets/OffersListWidget/OffersListWidgetLazy";

import {
  BannerPromoListResponseDataItem,
  BannerSearchListResponseDataItem,
  HeaderBannerListResponseDataItem,
} from "../api/content-client/service";
import { composeServerSideProps } from "next-composition";
import { withTranslations } from "../server/ssrprops/withTranslations";
import { withAllHotelsSsrProps } from "../server/ssrprops/withAllHotelsSsrProps";
import { withBannersSsrProps } from "../server/ssrprops/withBannersSsrProps";
import { withBannerPromosSsrProps } from "../server/ssrprops/withBannerPromosSsrProps";
import { withBannersSearchSsrProps } from "../server/ssrprops/withBannerSearchSsrProps";
import dataTestIds from "../utils/data-test-ids";
import { withSeoSsrProps } from "../server/ssrprops/withSeoSsrProps";
import { useEffect, useMemo, useState } from "react";
import { HEADER } from "../config-global";
import SwipeableDrawerForSearch from "../ui/components/FormSearch/SwipeableDrawerForSearch";
import SwipeableDrawerPuller from "../ui/components/FormSearch/SwipeableDrawerPuller";
import { useRecoilState } from "recoil";
import { uiBottomDrawerOpenedAtom } from "../state/ui-state";
import FormSearchSummarizedInput from "../ui/components/FormSearch/FormSearchSummarizedInput";
import { useInitialStateFromQuery } from "../hooks/useInitialStateFromQuery";

import type {
  ExtendedHotelDto,
  ExtendedRegionDto,
  HotelUnitGroupAttributeGroupsDto,
} from "../api/aggregation-client/service";
import { withUnitGroupAttributesSsrProps } from "../server/ssrprops/withUnitGroupAttributesSsrProps";

import { BannerWidget } from "../ui/widgets/BannerWidget/BannerWidget";
import { withRegionsListSsrProps } from "../server/ssrprops/withRegionsListSsrProps";
import { BannersSearch } from "../ui/components/property/BannersSearch";
// import { MapWidgetLazy } from "../ui/widgets/MapWidget/MapWidgetLazy";

const BannerPromos = dynamic(() => import("../ui/components/main/BannerPromos").then((x) => x.BannerPromos), {
  ssr: false,
});

HomePage.getLayout = (page: React.ReactElement, props: HomePageProps) => {
  return (
    <MainLayout withBackgroundImage hotels={props.hotels} banners={props.banners} enableMobileNav>
      {page}
    </MainLayout>
  );
};

type HomePageProps = {
  hotels: ExtendedHotelDto[];
  banners: HeaderBannerListResponseDataItem[];
  bannerPromos: BannerPromoListResponseDataItem[];
  bannersSearch: BannerSearchListResponseDataItem[];
  unitAttributes: HotelUnitGroupAttributeGroupsDto[];
  regions: ExtendedRegionDto[];
};

function HomePage({ hotels, bannerPromos, bannersSearch, unitAttributes, regions }: HomePageProps) {
  useInitialStateFromQuery();

  const t = useTranslation();
  const theme = useTheme();

  // TODO: Move into config
  const newArrivalAttributeIds = process.env.NODE_ENV !== "production" ? [30, 31, 34] : [952];
  const specialOffersAttributeIds = process.env.NODE_ENV !== "production" ? [30, 31, 34] : [918];

  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  const hasBannerPromos = bannerPromos && bannerPromos.length > 0;

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [isDrawerOpen, setDrawerOpen] = useRecoilState(uiBottomDrawerOpenedAtom);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    window.addEventListener("scroll", handleSearchFormScroll);
    return () => window.removeEventListener("scroll", handleSearchFormScroll);
  }, []);

  const handleSearchFormScroll = () => {
    const headerSearch = document.querySelector("#header-search-form");
    if (!headerSearch) {
      return;
    }
    const headerSearchRect = headerSearch.getBoundingClientRect();
    const isHeaderSearchBottomHidden = headerSearchRect.bottom <= (isXS ? HEADER.H_MOBILE : HEADER.H_MAIN_DESKTOP);
    setShowDrawer(isHeaderSearchBottomHidden);
  };

  const attributesPhotoSlider = useMemo(() => {
    const flattenAttributes = unitAttributes
      .map((g) => g.attributes)
      .flat()
      .filter((a) => a.show_in_main_slider)
      .sort((a, b) => Number(a.photo) - Number(b.photo) || b.priority - a.priority);

    return (
      <Box
        mt={1}
        mb={4}
        overflow={"hidden"}
        position={"relative"}
        maxWidth={"100%"}
        sx={{
          // TODO: add support custom horizontal scrollbar or scoll icon
          "&:after": {
            content: "''",
            position: "absolute",
            top: -20,
            bottom: -20,
            right: -80,
            width: 80,
            boxShadow: "-50px 0px 20px #fff",
          },
        }}
      >
        <Stack direction="row" spacing={1}>
          {flattenAttributes
            .filter((a) => !!a.photo)
            .map((a) => (
              <Box key={a.id}>
                <Button
                  size="small"
                  color="secondary"
                  sx={{ whiteSpace: "nowrap" }}
                  variant="outlined"
                  href={`/search?filters=${a.id}`}
                >
                  {a.title}
                </Button>
              </Box>
            ))}

          <Box>
            <Box mx={1} sx={{ background: theme.palette.grey[500], width: 2, height: "100%" }} />
          </Box>

          {flattenAttributes
            .filter((a) => !a.photo)
            .map((a) => (
              <Box key={a.id}>
                <Button
                  size="small"
                  color="secondary"
                  sx={{ whiteSpace: "nowrap" }}
                  variant="outlined"
                  href={`/search?filters=${a.id}`}
                >
                  {a.title}
                </Button>
              </Box>
            ))}
        </Stack>
      </Box>
    );
  }, [unitAttributes]);

  const mainPageBannersGrid = useMemo(() => {
    if (!bannersSearch || !bannersSearch.length) {
      return null;
    }
    return bannersSearch.filter((b) => b.attributes?.blockId === "mainPageSpecials");
  }, [bannersSearch]);

  return (
    <>
      {isXS && (
        <>
          <SwipeableDrawerPuller visible={showDrawer}>
            <FormSearchSummarizedInput onClick={() => setDrawerOpen(true)} />
          </SwipeableDrawerPuller>

          <SwipeableDrawerForSearch
            open={isDrawerOpen}
            onOpen={() => setDrawerOpen(true)}
            onClose={() => setDrawerOpen(false)}
          >
            <FormSearch isSheet navigateToSearchPage hotels={hotels} onSubmit={() => setDrawerOpen(false)} />
          </SwipeableDrawerForSearch>
        </>
      )}

      <Container maxWidth={"lg"}>
        {attributesPhotoSlider}

        {hasBannerPromos && <BannerPromos banners={bannerPromos} />}

        <Typography variant={"h4"} textAlign={"left"} sx={{ my: 2 }}>
          {t("index-search-new-in-az")}
        </Typography>

        {mainPageBannersGrid && mainPageBannersGrid.length > 0 ? (
          <Box my={4}>
            <Grid container spacing={4}>
              <BannersSearch showAll banners={mainPageBannersGrid} position={-1} currentPage={-1} />
            </Grid>
          </Box>
        ) : null}

        <Box mb={2}>
          <OffersListWidgetLazy
            bannersSearch={bannersSearch}
            bannerBlockId="mainPage1"
            showTotalPrice={false}
            guestsCount={2}
            type="future"
            perPage={4}
            infinite={false}
            dataTestId={dataTestIds.PAGES.HOME.FUTURE_OFFERS_CONTAINER}
            forcedAttributeIds={newArrivalAttributeIds}
          />
        </Box>
      </Container>

      {hotels && hotels.length > 0 && (
        <Box
          sx={{
            my: 4,
            // TODO: remove after FullScreenBlurredBackground activated
            backgroundColor: theme.palette.grey[200],
            py: 4,
            sm: {
              py: 3,
            },
          }}
        >
          <Typography variant={"h4"} textAlign={"center"} sx={{ mb: 4 }}>
            {t("Index_ourHotels")}
          </Typography>
          <Box sx={{ maxWidth: 1600, m: "auto", position: "relative" }}>
            {/* <FullScreenBlurredBackground /> */}
            <HotelListSlider items={hotels} />
          </Box>
        </Box>
      )}

      <Container maxWidth={"lg"}>
        <Typography variant={"h4"} sx={{ my: 4 }}>
          {t("Index_specialOffers")}
        </Typography>
        <Box mb={10}>
          <OffersListWidgetLazy
            bannersSearch={bannersSearch}
            showTotalPrice={false}
            guestsCount={2}
            type="future"
            perPage={12}
            infinite={false}
            dataTestId={dataTestIds.PAGES.HOME.SPECIAL_OFFERS_CONTAINER}
            forcedAttributeIds={specialOffersAttributeIds}
            bannerBlockId="mainPage2"
          />
        </Box>
      </Container>

      {regions && regions.length > 1 && (
        <Container maxWidth={"lg"}>
          <Typography variant={"h4"} sx={{ my: 4 }}>
            {t("Index_title_regions")}
          </Typography>
          <Box mb={10}>
            <Grid container spacing={2}>
              {regions
                .filter((r) => !!r.media)
                // TODO: add backend task:
                //  - strapi show_on_main_page boolean
                //  - aggregation api support
                .filter((_r, i) => i < 6)
                .map((r, regionIndex) => (
                  <Grid item key={r.id} xs={12} sm={6} md={regionIndex % 5 === 0 || regionIndex % 5 === 1 ? 6 : 4}>
                    <BannerWidget
                      linkUrl={`/info/${r.slug}`}
                      media={{
                        data: {
                          attributes: {
                            url: r.media,
                          },
                        },
                      }}
                      containerSx={{
                        minHeight: 250,
                      }}
                      title={r.title}
                      titleVariant="h5"
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Container>
      )}
    </>
  );
}

export default HomePage;

export const getServerSideProps = composeServerSideProps({
  use: [
    withAllHotelsSsrProps,
    withTranslations,
    withBannersSsrProps,
    withBannerPromosSsrProps,
    withBannersSearchSsrProps,
    withSeoSsrProps,
    withUnitGroupAttributesSsrProps,
    withRegionsListSsrProps,
  ],
});
